<template>
    <div
        ref="dropdown"
        v-on-clickaway="closeMenu"
        class="relative rounded"
        :class="fontSizeClass"
        :data-scope-id="manualScope"
        data-test-id="dropdown"
    >
        <v-style>
            [data-scope-id="{{ manualScope }}"] .dropdown-item:hover, .dropdown-item:hover div {
            background-color: {{ primaryColor }};
            color: {{ buttonTextColor }} !important;
            }
        </v-style>
        <div
            :class="isError ? 'border-red-500' : ''"
            class="flex justify-between items-center w-full p-2 transform-none overflow-hidden shadow-none cursor-pointer rounded border"
            :style="showMenu ? activeBoxStyle : (hover ? hoverBoxStyle : baseBoxStyle)"
            data-test-id="dropdown-toggle"
            @mouseover="hover = true"
            @mouseleave="hover = false"
            @click="toggleMenu()"
        >
            <input
                v-if="showSearch"
                ref="input"
                :value="searchText"
                class="w-full bg-transparent"
                :placeholder="placeholderText"
                :style="{
                    backgroundColor: backgroundColor,
                    color: textColor,
                }"
                @keyup.enter.stop="addSearchTextAsOther"
                @input="searchText = $event.target.value"
            >
            <span
                v-else
                class="truncate -mr-2"
                v-html="selectedOption && selectedOption.name !== undefined ? selectedOption.name : placeholderText"
            />
            <font-awesome-icon
                :icon="['fal', showMenu ? 'chevron-up' : 'chevron-down']"
                class="ml-2"
                :style="{ color: showMenu ? primaryColor : textColor}"
            />
        </div>
        <simplebar
            v-if="showMenu && hasOptions"
            class="condition-dropdown-menu absolute w-full left-0 list-none rounded-b bg-clip-padding border"
            style="top: 100%; margin-top: -4px; z-index: 1000;"
            :style="{
                borderColor: primaryColor,
                backgroundColor: backgroundColor,
                color: textColor,
                maxHeight: Math.min(maxHeight, 310) + 'px'
            }"
            data-test-id="dropdown-menu"
            @scroll="() => ({})"
        >
            <li
                v-if="showOtherOption"
                class="overflow-hidden w-full"
                :class="{'border-b border-neutral-500': dknaOption === null}"
            >
                <div
                    :class="{
                        'cursor-default': searchText === '',
                        'text-neutral-600': searchText === '',
                        'cursor-pointer': searchText !== '',
                    }"
                    :style="{borderColor: primaryColor}"
                    class="dropdown-item p-2"
                    @click.prevent.stop="addSearchTextAsOther"
                >
                    {{ searchText }}
                    <div class="text-yellow-800 text-sm">
                        {{ otherOptionTooltip }}
                    </div>
                </div>
            </li>
            <li
                v-if="showDknaOption"
                class="overflow-hidden w-full border-b border-neutral-500"
            >
                <div
                    :class="{
                        'cursor-default': selectedOption.value === 'dkna',
                        'text-neutral-600': selectedOption.value === 'dkna',
                        'cursor-pointer': selectedOption.value !== 'dkna',
                    }"
                    :style="{borderColor: primaryColor}"
                    class="dropdown-item p-2"
                    @click.prevent.stop="addDknaOption"
                >
                    {{ dknaOption }}
                </div>
            </li>
            <li
                v-for="(option, index) in filteredOptions"
                :key="index"
                class="overflow-hidden w-full"
            >
                <div
                    :class="{
                        'cursor-default': option.value === false,
                        'text-neutral-600': option.value === false,
                        'cursor-pointer': option.value !== false,
                    }"
                    :style="{borderColor: primaryColor}"
                    class="dropdown-item p-2"
                    @click.prevent.stop="option.value !== false ? updateOption(option) : ''"
                    v-html="option.name"
                />
            </li>
        </simplebar>
    </div>
</template>

<script>
import simplebar from 'simplebar-vue';
import { mixin as clickaway } from 'vue-clickaway';
import { getPrimaryColor, getTextColor, getBackgroundColor } from '@/utils/theme';
import {chooseButtonTextColor, hex2rgba} from '@/utils/color';
import { useResponsiveFontSizeClass } from '@/components/typeSpecificElements/hooks/useStyle';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VStyle from '@/components/ui/Style.vue';

import { computed } from 'vue';
import i18next from 'i18next';

export default {
    components: {
        VStyle,
        FontAwesomeIcon,
        simplebar,
    },
    mixins: [ clickaway ],
    props: {
        options: { type: [Array, Object], default: () => ([]) },
        selected: { type: Object, default: () => ({}) },
        placeholder: { type: String, default: '' },
        textColor: { type: String, default: getTextColor },
        primaryColor: { type: String, default: getPrimaryColor },
        backgroundColor: { type: String, default: getBackgroundColor },
        isFilterable: { type: Boolean, default: true },
        isError: { type: Boolean, default: false },
        searchOnly: { type: Boolean, default: false },
        hasOtherOption: { type: Boolean, default: false },
        dknaOption: { type: String, default: null }
    },
    setup(props) {
        const placeholderText = computed(() => {
            if (props.placeholder) return props.placeholder;

            return props.searchOnly ? i18next.t('QUESTION.START_TYPING') : i18next.t('QUESTION.OPTIONS_TITLE');
        });

        return {placeholderText};
    },
    data() {
        return {
            manualScope: Math.floor(Math.random() * 1000000),
            selectedOption: {
                name: '',
            },
            showMenu: false,
            searchText: '',
            hover: false,
            maxHeight: Infinity,
        };
    },
    computed: {
        filteredOptions() {
            if (this.searchOnly && this.searchText.length < 2) {
                return [];
            }
            return this.options.filter(o => {
                return o.name.toLowerCase().includes(this.searchText.toLowerCase());
            });
        },
        showOtherOption() {
            return this.hasOtherOption && this.searchOnly && this.searchText.length >= 2;
        },
        showDknaOption() {
            const hasDknaOption = this.dknaOption !== null;
            return hasDknaOption && !this.searchOnly || hasDknaOption && this.searchOnly && this.searchText.length >= 2;
        },
        hasOptions() {
            return this.filteredOptions.length > 0 || this.showOtherOption || this.showDknaOption;
        },
        exactSearchMatch() {
            if (!this.hasOtherOption || !this.searchOnly || this.searchText.length < 2) {
                return false;
            }
            return this.options.find(o => {
                // options can be formatted, so for exact string match html should be removed
                return o.name.replace(/<\/?[^>]+(>|$)/g, '').toLowerCase() === this.searchText.toLowerCase();
            });
        },
        otherOptionTooltip() {
            if (!this.hasOtherOption) {
                return '';
            }
            if (this.exactSearchMatch) {
                return this.$t('QUESTION.EXACT_MATCH', 'Exact match found');
            }
            return this.selectedOption.name === this.searchText ?
                this.$t('QUESTION.NO_EXACT_MATCH', 'No exact match in options') :
                this.$t('QUESTION.NO_EXACT_MATCH_ADD', 'No exact match in options, use it anyway');
        },
        showSearch() {
            return this.showMenu && (this.options.length > 3 && this.isFilterable || this.searchOnly);
        },
        buttonTextColor() {
            return chooseButtonTextColor(this.primaryColor, this.backgroundColor, this.textColor);
        },
        activeBoxStyle() {
            return {
                'background-color': 'transparent',
                'color': this.textColor,
                'border-color': this.primaryColor,
            };
        },
        hoverBoxStyle() {
            return {
                'background-color': 'transparent',
                'color': this.textColor,
                'border-color': this.textColor,
            };
        },
        baseBoxStyle() {
            return {
                'background-color': 'transparent',
                'color': this.textColor,
                'border-color': hex2rgba(this.textColor, .5),
            };
        },
        fontSizeClass() {
            const { answerFontSizeClass } = useResponsiveFontSizeClass();
            return answerFontSizeClass.value;
        },
    },
    watch: {
        selected() {
            this.updateSelectedOption();
        }
    },
    mounted() {
        this.updateSelectedOption();
    },
    methods: {
        updateSelectedOption() {
            this.selectedOption = this.selected;
            // If dropdown is search only, always pre-fill input with currently chosen value
            if (this.searchOnly && this.selected.name) {
                // Remove html formatting for search input
                this.searchText = this.selected.name.replace(/<\/?[^>]+(>|$)/g, '');
            }
        },
        updateOption(option) {
            this.selectedOption = option;
            this.showMenu = false;
            this.searchText = '';
            this.$emit('update-option', this.selectedOption);
        },
        addSearchTextAsOther() {
            if (this.searchText === '' || !this.hasOtherOption) {
                return;
            }
            if (this.exactSearchMatch) {
                this.updateOption(this.exactSearchMatch);
            } else {
                const otherOption = { name: this.searchText, value: 'other', index: null };
                this.updateOption(otherOption);
            }
        },
        addDknaOption() {
            this.updateOption({ name: this.dknaOption, value: 'dkna', index: null });
        },
        async toggleMenu() {
            // check how much space is there
            const filler = document.querySelector('.simplebar-content-wrapper');
            const space = filler.scrollHeight - (this.$refs.dropdown.getBoundingClientRect().bottom + filler.scrollTop);

            this.maxHeight = space - 10;

            if (this.options.length > 3 && this.isFilterable || this.searchOnly) {
                this.showMenu = true;
                await this.$nextTick();
                this.$refs.input.focus();
            } else {
                this.showMenu = !this.showMenu;
            }
        },
        closeMenu() {
            if (!this.showMenu) return;

            // If search text is not empty and other option is enabled, add search text as other option
            if (this.searchText !== '' && this.hasOtherOption) {
                this.addSearchTextAsOther();
            }

            this.showMenu = false;
            this.searchText = '';
        },
    }
};
</script>
